import { types } from '../types/types';


   
    //id: new Date().getTime(),
    //title: 'titulo',
    //observations: 'observations',
    //name: "juan",
    //lastname: "cualquiera",
    //dni: "1234567",
    //osId: '0',
    //shift_state: 'absent',
    //start: moment().toDate(),
    //end: moment().add(1,'hours').toDate(),
    //bgcolor: '#fafafa',
    //notes: 'notas',
    //user: {
    //    _id: '123',
    //    name: 'Perez'
    //},
    //start: '',
    //end: ''


const initialState = {
    shifts: [ ],
    activeShift: null,
    shiftQueue: []
};


export const calendarReducer = ( state = initialState, action) => {

    switch ( action.type ) {
       
        case types.shiftSetActive:
            return {
              ...state,
              activeShift: action.payload  
            };
            
        case types.shiftAddNew:
            return {
                ...state,
                shifts: [
                    ...state.shifts,
                    action.payload
                ]
            }

        case types.shiftActiveClear:
            return {
                ...state,
                activeShift: null,
            }
        
        case types.shiftUpdate:
            return {
                ...state,
                shifts: state.shifts.map(
                    shift => ( shift.id === action.payload.id) ? action.payload : shift
                )
            }

        case types.shiftDeleted:
             return {
                ...state,
                shifts: state.shifts.filter(
                    shift => ( shift.id !== state.activeShift.id)
                ),
                activeShift: null
            }

        case types.shiftDeletedWhenPatientDeleted:         
            return {
                ...state,
                shifts: state.shifts.filter(
                    shift => ( shift.patientId !== action.payload)
                ),
                activeShift: null
            }

        case types.shiftLoaded:
            return{
                ...state,
                shifts: [...state.shifts, ...action.payload]
            }
            
        case types.shiftLogout:
            return {
                ...initialState
            }

        case types.shiftClearAll:
            return {
                ...initialState
            }
            
        case types.shiftQueue:
            return {
                ...state,
                shiftQueue: [...state.shiftQueue, action.payload]
            }

        case types.shiftClearQueue:
            return {
                ...state,
                shiftQueue: []
            }    

        default:
            return state;
    }
};