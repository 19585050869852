import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Button, TextField }  from '@material-ui/core';
import {
    Link
  } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { startLogin } from '../../actions/auth';


import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';


const useStyles = makeStyles((theme) => ({
    root: {
    
        backgroundColor: '#525f7f',
        width:'100%',
        height:'100vh'
      
    },
    paper: {
      width: '300px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
      //backgroundColor: '#e9ecef'
    },
    loginIcon: {
        fontSize: '3rem',
        marginBottom: '1rem',
        color: '#FE6B8B'
    },
    formContainer: {
      display:'flex',
      flexDirection:'column',
      alignItems:'center'
      
    },
    buttonLogin: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
        marginTop: '10px',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -10,
        marginLeft: -12,
    },
    fomLinks: {
        marginTop:'2rem'
    },
    link: {
        textDecoration: 'none',
        cursor: 'pointer',
        color: '#334',
        textTransform: 'lowercase',
        fontFamily: 'arial'
    },
    errorMessage: {
        color: 'red !important',
        fontFamily: 'arial',
        textTransform: 'uppercase',
        fontSize: '.7rem'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
  }));

export const LoginScreen = () => {
    const classes = useStyles();

    const dispatch = useDispatch();
    //if login fail
    const { loginErrors } = useSelector(state => state.auth);
    const [loading, setLoading] = React.useState(false);

    const [ formLoginValues, setFormLoginValues ] = useState({
        email: '',
        password: ''
    });

    //checkbox
    const [state, setState] = React.useState({
        rememberMe: false
    });

    let rememberEmail = localStorage.getItem('email');

    useEffect(() => {
      if (rememberEmail) {
           setState({rememberMe: true});
           setFormLoginValues({...formLoginValues, email: rememberEmail});

      }
    },[state.rememberMe])

    const {email, password } = formLoginValues;

    const handleLoginInputChange = ({ target }) => {
        setFormLoginValues({ ...formLoginValues, [target.name]: target.value});
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        await dispatch(startLogin(email, password));
        setLoading(false);
    };


 
    
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        if (event.target.checked && formLoginValues.email) {
            localStorage.setItem('email', email)
        }else{            
            localStorage.removeItem('email', email);
            rememberEmail = '';
        }
    };
    

    return (
            <Grid className={classes.root} container direction="column" justify="center" alignItems="center">
          
                    <Paper className={classes.paper}>
                        <AccountCircleOutlinedIcon className={classes.loginIcon} />

                        <form className={classes.formContainer} onSubmit={ handleLogin }>

                            <TextField
                                required
                                id="outlined-required"
                                label="Email"
                                variant="outlined"
                                type="email"
                                name="email"
                                value={rememberEmail ? rememberEmail : email}
                                onChange={handleLoginInputChange}
                                />
                            <TextField
                                required
                                id="outlined-password-input"
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                variant="outlined"
                                name="password"
                                value={password}
                                onChange={handleLoginInputChange}
                                />

                            { (loginErrors) && <h5 className={classes.errorMessage}>{ loginErrors }</h5> }
                            
                            <FormControlLabel
                                    control={<Checkbox checked={state.rememberMe} onChange={handleChange} name="rememberMe" />}
                                    label="Rcuerdame"
                                />
                            <div className={classes.wrapper}>
                                <Button className={classes.buttonLogin} type="submit" variant="contained" disabled={loading}>
                                    {loading ? 'LOADING...' : 'LOGIN'}
                                </Button>
                                {loading && <CircularProgress size={34} color={'secondary'} className={classes.buttonProgress} />}
                            </div>
                        </form>

                        <Grid className={classes.fomLinks} container direction="row" justify="space-around" alignItems="center">
                            <Link
                            to="/forgot-password"
                            className={classes.link}
                            >
                            Olvide mi contraseña
                            </Link>
                        </Grid>
                    </Paper>            
                
            </Grid>
        
    )
}
