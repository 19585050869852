export const types = {

    //shift modal
    uiOpenModal:             '[ui] Open modal',
    uiCloseModal:            '[ui] Close modal',
    uiOpenPatientFormModal:  '[ui] Open patient form',
    uiClosePatientFormModal: '[ui] Close patient form',
    uiPatientFormErrors:     '[ui] Set patient form errors',
    uiPatientFormErrorsReset:'[ui] Clear patient form errors',
    uiPatientFormValidated:  '[ui] Check patient form validation',

    //shift form
    shiftSetForm:       '[Shift form] add start-end',
    shiftUnsetForm:     '[Shift form] unset start-end',
    shiftActiveClear:   '[Shift form] clear active shift',
    
    //calendar
    shiftStartAddNew:   '[shift] Start add new Shift',
    shiftSetActive:     '[shift] Set Active',
    shiftAddNew:        '[shift] Add new',
    shiftUpdate:        '[shift] Shift updated',
    shiftDeleted:       '[shift] Shift deleted',
    shiftLoaded:        '[shift] Shifts loaded',
    shiftLogout:        '[shift] Clear active shift at logout',
    shiftQueue:         '[Shift] shift queue control',
    shiftClearAll:         '[Shift] shift clear all',
    shiftClearQueue:         '[Shift] shift clear queue',
    shiftDeletedWhenPatientDeleted:       '[shift] Shift of patient deleted',

    //auth
    authCheckingFinish: '[auth] Finise checking login state',
    authStartLogin:     '[auth] Start login',
    authLogin:          '[auth] Login',
    authLoginFail:      '[auth] Login Failed',
    authSatrtRegister:  '[auth] Start register',
    authTokenRenew:     '[auth] Start token renew',
    authLogout:         '[auth] Logout',

    //os
    osGetAll:           '[os] Get All',
    osAllLoaded:        '[os] All os loaded',

    //patients
    patientGetAll:          '[patient] Get All',
    patientsAllLoaded:      '[patient] All patients loaded',
    patientStartAddNew:     '[patient] Start add new Patient',
    patientSetActive:       '[patient] Set Active',
    patientAddNew:          '[patient] Add new',
    patientUpdate:          '[patient] Patient updated',
    patientDeleted:         '[patient] Patient deleted',
    patientActiveClear:     '[patient form] clear active patient',
    patientDetailLoaded:    '[patient] patient detail loaded',

    //statics
    staticsGeneral:      '[statics] Get general statics',


    //user
    userGetPreferences:     '[user] Get preferences',
    userRemoveBlockedDate:  '[user] Remove blocked date',
    userBlockDate:          '[user] blocked date',
    userGetMedics:          '[user] get medics',
    userSelectMedic:        '[user] select medic'

}