import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userSelectMedic, userStartGetMedics, userStartGetPreferences } from '../../actions/user';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';
import { shiftClearAll,  shiftStartLoading } from '../../actions/shifts';

export const MedicsList =  () => {
    const dispatch = useDispatch();
    const { role } = useSelector(state => state.auth);
    const { medics } =  useSelector( state => state.user);
  
    useEffect(() => {
        if (role === 'Secretary') {            
            dispatch(userStartGetMedics());
        }
        
    }, [])
  
    
    if (role !== 'Secretary') {
        return (<></>);
    }
    
    return (
        <div>
            <Autocomplete
                id="combo-box-demo"
                options={medics}
                defaultValue={medics[0]}
                getOptionLabel={(option) => `${option.lastName}, ${option.firstName}`}
                renderInput={(params) => <TextField {...params } name="ownerId" label="Seleccione un Médico" defaultValue="Médico" variant="outlined" />}
                onChange={(event, newValue) => { 
                if (newValue) {
                    console.log(JSON.stringify(newValue, null, ' '));
                    const ownerId = newValue.id;
                    //when medic change, need to reset preferences and ShiftQueue
                    dispatch(shiftClearAll());
                    dispatch( userStartGetPreferences(ownerId));
                    dispatch(userSelectMedic(ownerId));
                    dispatch(shiftStartLoading({ownerId: ownerId}));
                    //at change, needs to remobe shifts from store and get news shifts for the medic selected
                //ownerId: newValue.id
                }
                }}
            />
            <Box height={10}></Box>
        </div>
    )
}
