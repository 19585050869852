import { types } from "../types/types";
import { fetchAuth } from "../helpers/fetch";
import Swal from 'sweetalert2';

export const staticsGetGeneral = (month, year) => {
    return async (dispatch) => {

        try {
            const resp = await fetchAuth(`statics/general?month=${month}&year=${year}`);
            const body = await resp.json();

            if (body.ok) {
                
                dispatch(staticsGeneral(body));

            }else{

                Swal.fire({
                    title: '<p>Error</p>',
                    icon: 'error',
                    text: body.message 
                });

            }

        } catch (error) {
            console.log(error);
        }
    }
}

const staticsGeneral = (statics) => ({
    type: types.staticsGeneral,
    payload: statics
});