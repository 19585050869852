import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AddToQueueOutlinedIcon from '@material-ui/icons/AddToQueueOutlined';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '4em',        
        padding: '1.2em',
        backdropFilter: 'blur(5px)'
    },
    patientName: {
        textTransform: 'uppercase',
        color: '#005278'
    },
    patientData: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'lowercase',
        marginBottom: '0.5em'        
    },
    icon: {
        marginRight: '15px'
    },
    locateContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '1em'
    },
    locate: {
        display: 'flex',
        flexDirection: 'column'
    }
  }));


export const PatientDataDetail = ( props ) => {
    const classes = useStyles();
    const patient = props.patient;

    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12} className={classes.patientName}>
                <div>
                    <h1>{ patient?.lastName }, { patient.firstName } </h1> 
                    {patient.age} años
                </div>
            </Grid>

            <Grid item xs={12}  md={4}> 
                <Typography className={classes.patientData} >
                    <FingerprintIcon className={classes.icon}/> 
                    {patient.dni}
                </Typography>
                <Typography className={classes.patientData}>
                    <HomeOutlinedIcon className={classes.icon} /> {patient?.address}
                </Typography>
                <div className={classes.locateContainer}>
                    <RoomOutlinedIcon/>
                    <div className={classes.locate}>
                        <Typography>{patient?.country}</Typography>
                        <Typography color="textSecondary">{patient?.province}</Typography>
                    </div>
                </div>
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography className={classes.patientData}>
                    <AddToQueueOutlinedIcon className={classes.icon} /> {patient.os.name}
                </Typography>
                {
                    patient.osNumber &&
                    <Typography className={classes.patientData} >
                        <ContactMailOutlinedIcon className={classes.icon}/> 
                        {patient?.osNumber}
                    </Typography>
                }
                                
                <Typography className={classes.patientData}>
                    <EmailOutlinedIcon className={classes.icon} /> {patient?.email}
                </Typography>
                <Typography className={classes.patientData}>
                    <PhoneOutlinedIcon className={classes.icon} /> {patient?.telephone}
                </Typography>
            </Grid>
            
        </Grid>
    )
}
