import React, { useEffect, useState } from 'react'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'


import { messages } from '../../helpers/calendar-messages-es'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { CalendarEvent } from './CalendarEvent'
import { CalendarModal } from './CalendarModal'

//redux
import { uiOpenModal,shiftSetForm } from '../../actions/ui'
import { shiftSetActive, shiftStartLoading } from '../../actions/shifts'

import 'moment/locale/es'
import { DeleteShiftFab } from '../ui/DeleteShiftFab'
import { getPatientHistory } from '../../helpers/patientGetHistory'
import { prepareTime } from '../../helpers/prepareHours'
import { BlockDay } from './BlockDay'
import { MedicsList } from './MedicsList'
moment.locale('es');

const localizer = momentLocalizer(moment);




export const CalendarScreen =() => {

    const dispatch = useDispatch();
    const { role } = useSelector(state => state.auth);
    const { preferences, blockDays, selectedMedic } = useSelector(state => state.user)
    const { shifts, activeShift } = useSelector(state => state.calendar);

   
    useEffect(() => {
      //get shifts for actual month and year
      dispatch(shiftStartLoading({ownerId: selectedMedic}));  
    }, [preferences]);


    const [lastView, setlastView] = useState(localStorage.getItem('lastView') || 'month');


    //TODO : make a function to load the data on form, repeated code at dobuble and select event
    const onDoubleClick = async (e) => {
      const { observations, state, patientId, id } = e;
      //extract data from patient
      const {lastName, address, dni, email,
        birthDate,  firstName, telephone,
        country, province, osId,  osNumber} = e.patient;
        dispatch( uiOpenModal() );
        
        //get shifts history for patient
      const patientHistory = await getPatientHistory(dni);

      dispatch( shiftSetActive(
        {id, patientId, lastName, address, dni, email,
          birthDate, firstName, telephone,
          country, province, state, observations, 
          osId, osNumber, patientHistory}
      ));
    }
      
    const onSelectEvent = (e) => {        
      //dispatch( uiOpenModal());
      const { observations, state, patientId, id } = e;
      //extract data from patient
      const {lastName, address, dni, email,
        birthDate,  firstName, telephone,
        country,province, osId,  osNumber} = e.patient;

      dispatch( shiftSetActive(
        {id, patientId, lastName, address, dni, email,
        birthDate, firstName, telephone,
        country,province, state, observations, 
        osId, osNumber}
      ));
    }

    //const userWeek = [
    //  {day: 2, fromHourAM: '12:00', toHourAM: '13:00', fromHourPM: '15:00', toHourPM: '18:00'},
    //  {day: 4, fromHourAM: '11:00', toHourAM: '13:00', fromHourPM: '24:00', toHourPM: '24:00'}
    //];
    const userWeek = preferences;


    //function to check if date was blocked by user
    const checkBlockedDate = ( date ,blockDays ) => {
      const completeDate = `${("0" + (date.getDate())).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
    
        if( blockDays.includes(completeDate) ){
          return true;
        }else{
          return false;
        }
    }
  


    const onSelectSlot = ({ start, end }) => {
  
      const day = start.getDay();
      //disbable click on month view
      if (localStorage.getItem('lastView') === 'month') {
        return;
      }
      
      //block click on unabailable days
      if (!userWeek.filter( userDay => (userDay.day === day )).length > 0) { 
        return;
      }

      const blockedDate = checkBlockedDate(start, blockDays);
      if (blockedDate) {
        return;
      }

      //check if days available have unavailable hours and block them
      if (userWeek.filter( userDay => 
        (userDay.day === day && start < prepareTime(start,userDay.fromHourAM))              
        ||
        (userDay.day === day && start > prepareTime(start,userDay.toHourAM))
        &&
        (userDay.day === day && start < prepareTime(start,userDay.fromHourPM))              
        ||
        (userDay.day === day && start > prepareTime(start,userDay.toHourPM))
        ).length > 0) { 
        return;
      }

      
      //check if there is not a shift already set in that time selected
        const alreadyShift = shifts.filter(
          shift => 
            shift.start.toString() === start.toString() && shift.end.toString() === end.toString() 
          );
          if( alreadyShift.length === 0){
          //show the form and send te date selected start end to the store          
          dispatch( uiOpenModal());
          dispatch(shiftSetForm({ start, end}))
        };
    }

    const onViewChange = (e) => {
        setlastView(e);
        localStorage.setItem('lastView',e);
    }

  
    
    //check blocked and works days
    const customDayPropGetter = date => {
      const day = date.getDay();      
      //check if date is blocke
      const blockedDate = checkBlockedDate(date, blockDays);
      if (blockedDate) {
        return {
          className: 'special-day',
          style: {
            //TODO: fix in month view remove display none
            //display: 'none', //remove for show those days
            border: 'solid 1px #005278' ,
            backgroundColor: '#005278',
            color: '#fff'
          },
        }
      }

      //if work day not in user preferences
      if (!userWeek.filter(userDay => userDay.day === day).length > 0 ) {          
        return {
          className: 'special-day',
          style: {
            //TODO: fix in month view remove display none
            //display: 'none', //remove for show those days
            border: 'solid 1px #005278' ,
            backgroundColor: '#005278',
            color: '#fff'
          },
        }
      }else{

        return {}
      }
        
    }
      
    //check hours ranges
    const customSlotPropGetter = date => {
      
      const day = date.getDay();
      const blockedDate = checkBlockedDate(date, blockDays);

      if (blockedDate) {
        return {
          className: 'special-day',
          style: {
            //fix in month view remove display none
            //display: 'none', //remove for show those days
            border: 'solid 1px #891327' ,
            backgroundColor: '#891327',
            color: '#fff'
          },
        }
      }

        //if day not in userWeek preferences
        if (!userWeek.filter(userDay => userDay.day === day).length > 0){
            return {            
            className: 'special-day',
            style: {
                border: 'solid 1px transparent' ,
            }
          }
        }
        if (
          //unset slots for days and hours indicated in userWeek prefenrences          
          (userWeek.filter(
              userDay => 
              (userDay.day === day && date < prepareTime(date,userDay.fromHourAM))              
              ||
              (userDay.day === day && date > prepareTime(date,userDay.toHourAM))
              &&
              (userDay.day === day && date < prepareTime(date,userDay.fromHourPM))              
              ||
              (userDay.day === day && date > prepareTime(date,userDay.toHourPM))
            ).length > 0)
         ){
          return {
            className: 'special-day',
              style: {
                //TODO: fix in month view remove display none
                //display: 'none', //remove for show those days
                border: 'solid 1px #ff7575' ,
                backgroundColor: '#ff7575',
                color: '#fff'
              },
            }
         } 
         
      }

 
    
    //show shifts by months
    const onNavigate = (date, view, action) => {
      const year  = moment(date).format('YYYY');
      const month = moment(date).format('MM');
      dispatch(shiftStartLoading({month, year, ownerId: selectedMedic}));
    }

        
    return (
        <div className="calendar-screen">
          <MedicsList />
          { role === 'Medic' && <BlockDay /> }
          <Calendar
              selectable={true}
              slotPropGetter={customSlotPropGetter}
              dayPropGetter={customDayPropGetter}
              defaultView="week"
              step={15}
              timeslots={1}
              min={new Date(0, 0, 0, 11, 0, 0)}
              max={new Date(0, 0, 0, 18, 0, 0)}
              localizer={localizer}
              events={shifts}
              startAccessor="start"
              endAccessor="end"
              messages={messages}
              onNavigate={onNavigate}
              onDoubleClickEvent={onDoubleClick}
              onSelectEvent={onSelectEvent}
              onSelectSlot={onSelectSlot}
              onView={onViewChange}
              view={lastView}
              longPressThreshold={5}
              components={{
                  event: CalendarEvent,  
              }}
                
          />

          {
            (activeShift) && <DeleteShiftFab/>
          }
            
          <CalendarModal></CalendarModal>
        </div>
    )
}
