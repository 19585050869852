import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    paper: {
      //padding: '6px 26px',
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
    medic: {
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        color: 'white',
        textTransform: 'uppercase',
        background: '#417E9A',
        padding: '8px 6px',
        display: 'flex',
        alignItems: 'center',
    },
    medicIcon: {
        marginRight: '10px'
    },
    observations: {
        padding: '15px 10px'
    }
  }));

export const PatientTimeline = ( props ) => {

    const classes = useStyles();

    return (
        <Timeline align="alternate">
            { props.shifts.map(
                shift => {
                    return (
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <Typography variant="body1" color="textSecondary">
                                {moment(shift.start).format('D/M/YYYY')}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {moment(shift.start).fromNow()}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineDot>
                            <EventIcon/>                            
                        </TimelineDot>
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                                <Typography className={classes.medic} variant="h6" component="h6">
                                <LocalHospitalIcon className={classes.medicIcon} />
                                { shift.user?.lastName }, { shift.user?.firstName}
                                </Typography>
                                <Typography className={classes.observations}>{shift.observations}</Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    );
                }
            )}
            
        </Timeline>
    )
}
