import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { patientSetActive, patientStartDelete } from '../../actions/patient';
import Swal from 'sweetalert2';
import {  uiOpenPatientFormModal } from '../../actions/ui';
import { Link } from 'react-router-dom';

export const MenuDataGrid = (props) => {
    const dispatch = useDispatch();
    const { patients } = useSelector(state => state.patient);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    //search patiet in the list
    const getPatient = (patientId) => {
        let [patient ] =  patients.filter(patient => patient.id === patientId);
        return patient;
    }

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const editPatient = (id) => {
        const patient = getPatient(id);
        dispatch(patientSetActive(patient));
        dispatch(uiOpenPatientFormModal(patient));
        setAnchorEl(null);
    }
   
    const deletePatient = (id) => {
        dispatch(patientSetActive(getPatient(id)));
        Swal.fire({
            title: "Estás seguro?",
            text: "Una vez eliminado, no se podra recuperar!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
          })
          .then((willDelete) => {
            if (willDelete.isConfirmed) {
            dispatch(patientStartDelete(getPatient(id)));              
            }else{
                //clear selected patient
            }
          });
        setAnchorEl(null);
    }

    return (
    <>
        <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        >
        <MenuItem  onClick={() => { editPatient(props.patientId)}}>
            <ListItemIcon>
                <EditIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Editar</Typography>
        </MenuItem>
        <MenuItem  >
            <Link className="patientLink" to={`paciente/${props.patientId}`}>
            <ListItemIcon>
                <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Ver</Typography>            
            </Link>
        </MenuItem>
        <MenuItem  onClick={() => { deletePatient(props.patientId)}}>
            <ListItemIcon>
                <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Eliminar</Typography>
        </MenuItem>

      </Menu>
    </>
    )
}
