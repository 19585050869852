import React from 'react';
import { Pie } from '@reactchartjs/react-chart.js';

export const ChartsForShifts = ( { labels, values } ) => {
    
    const data = {
        labels: labels,
        datasets: [
          {
            label: '# of Votes',
            data: values,
            backgroundColor: [
              'rgba(219, 54, 54, 0.2)',
              'rgba(139, 139, 139, 0.2)',
              'rgba(201, 253, 188, 0.2)',
            ],
            borderColor: [
              'rgba(219, 54, 54, 1)',
              'rgba(139, 139, 139, 1)',
              'rgba(0, 149, 0, 1)',
            ],
            borderWidth: 1,
          },
        ],
      }

    return (
        <>
            <Pie data={data} />
        </>
    )
}
