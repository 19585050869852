import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Button, TextField, Link }  from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';


import Swal from 'sweetalert2';
import { fetchPublic } from '../../helpers/fetch';


const useStyles = makeStyles((theme) => ({
    root: {
    
        backgroundColor: '#525f7f',
        width:'100%',
        height:'100vh'
      
    },
    paper: {
      width: '300px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
      //backgroundColor: '#e9ecef'
    },
    loginIcon: {
        fontSize: '3rem',
        marginBottom: '1rem',
        color: '#FE6B8B'
    },
    formContainer: {
      display:'flex',
      flexDirection:'column',
      alignItems:'center'
      
    },
    buttonLogin: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
        marginTop: '10px',
    },
    text: {
        fontSize: '1rem',
        textAlign: 'center',
        color: '#444',
        textTransform: 'uppercase',
        fontFamily: 'arial'
    },
    errorMessage: {
        color: 'red !important'
    }
  }));

export const ForgotPassword = () => {
    const classes = useStyles();

    const [formValues, setformValues] = useState({
        email: ''
    });
    
    const { email } = formValues;

    const handleInputChange = ({ target }) => {
        setformValues({email: target.value});
    }

    const dispatch = useDispatch();
    //if login fail
    const { loginErrors } = useSelector(state => state.auth);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const resp = await fetchPublic('auth/forgot-password',{email}, 'POST');
        const body =  await resp.json();

        if (body.ok) {
            Swal.fire({
                title: '<p>Exito</p>',
                icon: 'success',
                text:  'Se envió un email para recuperar la contraseña'
            });
        }

        setformValues({email: ''});
    }

    return (
            <Grid className={classes.root} container direction="column" justify="center" alignItems="center">
          
                    <Paper className={classes.paper}>
                        <h5 className={classes.text}>Ingrese su dirección de e-mail para recuperar su contraseña</h5>

                        <form className={classes.formContainer} onSubmit={ handleFormSubmit }>

                            <TextField
                                required
                                id="outlined-required"
                                label="Email"
                                variant="outlined"
                                type="email"
                                name="email"
                                value={email}
                                onChange={handleInputChange}
                                />
                            { (loginErrors) && <h5 className={classes.errorMessage}>{ loginErrors }</h5> }
                      
                            <Button className={classes.buttonLogin} type="submit" variant="contained">
                                Enviar
                            </Button>
                        </form>
                    </Paper>            
                
            </Grid>
        
    )
}
