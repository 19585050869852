import moment from 'moment'

export const prepareShifts = (shifts = []) => {

    return shifts.map(
        (shift) => ({
            ...shift,
            end: moment(shift.end).toDate(),
            start: moment(shift.start).toDate(),
        })
    );
};