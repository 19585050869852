import { fetchAuth } from '../helpers/fetch';
import { types } from '../types/types';


export const userStartGetPreferences = ( ownerId ) => {
    return async (dispatch) => {
        
        const resp =  await fetchAuth(`preferences?ownerId=${ownerId}`);
        const body =  await resp.json();

        if(body.ok){
            console.log("obteniendo preferencias del usuario", body);
           dispatch( userGetPreferences(body)); 

        }else{

            console.log("error");
        }
    }
};

const userGetPreferences = ( userPreferences ) => ({
    type: types.userGetPreferences,
    payload: userPreferences
});

export const usertStartBlockDate =  ( daysToBLock ) => {
    return async (dispatch) => {
        
        const resp = await fetchAuth('preferences/blockdays', {daysToBlock: daysToBLock} ,'PUT');
        const body = await resp.json();

        if (body.ok) {
            dispatch(userBlockDate(body.blockedDays));
        }else{
            console.log("error al intentar eliminar fecha bloqueada");
        }
    }
}

const userBlockDate = ( updateList ) => ({
    type: types.userBlockDate,
    payload: updateList
});

export const usertStartRemoveBlockedDate =  ( dateToRemove ) => {
    return async (dispatch) => {

        const resp = await fetchAuth('preferences/blockdays',{ dayToRemove: [dateToRemove] },'DELETE');
        const body = await resp.json();

        if (body.ok) {
            
            dispatch(userRemoveBlockedDate(body.updatedList));
        }else{
            console.log("error al intentar eliminar fecha bloqueada");
        }
    }
}

const userRemoveBlockedDate = ( updateList ) => ({
    type: types.userRemoveBlockedDate,
    payload: updateList
});




export const userStartGetMedics = () => {
    return async (dispatch) => {
        
        const resp =  await fetchAuth('user/medics');
        const body =  await resp.json();
        const { medics } = body;

        if(body.ok){
           
           dispatch( userGetMedics(medics)); 

        }else{
            console.log("error");
        }
    }
};


const userGetMedics = ( medics ) => ({
    type: types.userGetMedics,
    payload: medics
});


export const userSelectMedic = ( medicId ) => ({
    type: types.userSelectMedic,
    payload: medicId
});