import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';

//data picker for the birthdate
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { es } from "date-fns/locale";
import Autocomplete from '@material-ui/lab/Autocomplete';
//---------------------------------------------------------------

import { shiftUnsetForm, uiClosePatientFormModal, uiPatientFormErrorsReset } from '../actions/ui';
import { shiftActiveClear } from '../actions/shifts';
import { patientActiveClear, patientStartAddNew, patientStartUpdate } from '../actions/patient';


export const PatientFormModal = () => {

    const { patientFormModalOpen, 
            initialPatientForm, 
            patientFormErrors,
            patientFormValidate
         } = useSelector( state => state.ui);
    const { activePatient } = useSelector( state => state.patient);
    const listOs = useSelector(state => state.os.os);
    const dispatch = useDispatch();

    
    //form
    const [formValues, setFormValues] = useState(initialPatientForm);
    const { lastName, address, dni, email, 
            birthDate,  firstName, telephone, province,
            country, osNumber } = formValues;


    const { lastNameError, addressError, dniError, emailError, 
            birthDateError,  firstNameError, telephoneError, provinceError,
            countryError,   osNumberError } = patientFormErrors;        
    

    useEffect(() => {
      if( activePatient ){
        setFormValues( activePatient );
      }else{
        //if form open for new patient, clear it
        setFormValues( initialPatientForm );
      }

    }, [activePatient]);

    const handleInputChange = ({ target }) => {
     
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }
  
    const handleFormSubmit = (e) => {
        e.preventDefault();
        //editing one or adding new patient
        if ( activePatient ) {
          dispatch(patientStartUpdate({...formValues}));
         //dispatch( patientStartAddNew( {...formValues, osId: selectedOs} ) );
        }else{
          dispatch(patientStartAddNew({
            ...formValues
          }));
        }
        
        //clear form
        if (patientFormValidate) {            
            setFormValues(initialPatientForm);
        }
        //clear start end date from store
        dispatch(shiftUnsetForm());
        //unset active event from store
        dispatch(shiftActiveClear());
        //reset selected os
        //setSelectedOs(0);
    }
    //end form


    const handleClose = () => {
        dispatch(patientActiveClear());
        dispatch(uiPatientFormErrorsReset());
        dispatch( uiClosePatientFormModal());
        setFormValues(initialPatientForm);

    };

    //datapicker for born date
    const [selectedDate, setSelectedDate] = React.useState(new Date('2015-08-18'));

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setFormValues({
            ...formValues,
            birthDate: selectedDate
        });
    };

  
    //dialog
    const [openAlert, setOpenAlert] = React.useState(false);
     
    const handleCloseAlert = () => {
      setOpenAlert(false);
      window.location.reload();
    };

    return (
      <div>
        <Dialog open={ patientFormModalOpen } onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
        <DialogTitle id="form-dialog-title">{ (activePatient) ? 'Editar Paciente' : 'Nuevo Paciente' }</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          <form onSubmit={handleFormSubmit}>
            <Grid container direction="column" alignContent="center" justify="center">
  

              <Grid container  spacing={3}>

                  <Grid item xs={12} lg={6}>
                    <FormGroup> 
                      <TextField error={!!lastNameError} value={lastName} onChange={handleInputChange} name="lastName" label="Apellido" variant="outlined" helperText={lastNameError} />
                      <TextField error={!!firstNameError} value={firstName} onChange={handleInputChange} name="firstName" label="Nombre" variant="outlined" helperText={firstNameError} />
                      <TextField error={!!telephoneError} value={telephone} onChange={handleInputChange} name="telephone" label="Telefono" variant="outlined" helperText={telephoneError} />
                      <TextField error={!!addressError} value={address} onChange={handleInputChange} name="address" label="Dirección" variant="outlined" helperText={addressError} />
                      <MuiPickersUtilsProvider  utils={DateFnsUtils} locale={es} >
                          <KeyboardDatePicker  
                              helperText={birthDateError}
                              animateYearScrolling="true"
                              disableFuture="true"
                              variant="inline"
                              inputVariant="outlined"
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              name="birthDate"
                              label="Fecha de Nacimiento"
                              value={birthDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                        </MuiPickersUtilsProvider>
                      </FormGroup> 
                  </Grid>
               
                  <Grid item xs={12} lg={6}>
                    <FormGroup>
                    <TextField error={!!dniError} value={dni} onChange={handleInputChange} name="dni" label="DNI" variant="outlined" type="number" helperText={dniError} />
                    <TextField error={!!emailError} value={email} onChange={handleInputChange} name="email" label="Email" variant="outlined" type="email" helperText={emailError}/>
                    <TextField error={!!countryError} value={country} onChange={handleInputChange} name="country" label="Pais" variant="outlined" helperText={countryError}/>
                    <TextField error={!!provinceError} value={province} onChange={handleInputChange} name="province" label="Provincia" variant="outlined" helperText={provinceError}/>
                    </FormGroup>    
                      
                  </Grid>
              
                </Grid>

                <Grid item lg={8}>
                  <FormGroup>
                  <Autocomplete
                      id="combo-box-demo"
                      options={listOs}
                      defaultValue={activePatient ? listOs[activePatient.osId - 1] : listOs[0]}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params } name="os" label="Obra Social" defaultValue="Particular" variant="outlined" />}
                      onChange={(event, newValue) => { 
                        if (newValue) {
                          console.log(JSON.stringify(newValue, null, ' '));
                        setFormValues({...formValues, osId: newValue.id, os: listOs[newValue.id - 1].name});
                        }
                      }}
                    />
                    <Box m={0.2} />
                    <TextField error={!!osNumberError} value={osNumber} onChange={handleInputChange} name="osNumber" label="N°" variant="outlined"  helperText={osNumberError}/>
                    
                  
                  </FormGroup>
                  
                </Grid>

            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleFormSubmit} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">La sesión expiró</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Inicie sesión nuevamente
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseAlert} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    )
}
