import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { staticsGetGeneral } from '../../actions/statics';
import { ChartsForShifts } from './ChartsForShifts';
import { ChartsForOs } from './ChartsForOs';
import moment from 'moment';



export const ShiftChart = () => {
    
    const dispatch = useDispatch();
    const { listStates, valuesListShifts, os, valuesListOs } = useSelector(state => state.statics);

    const year  = moment().format('YYYY');
    const month = moment().format('MM');

    useEffect(() => {
        dispatch(staticsGetGeneral(month, year));
    }, [])
    


    return (
        <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
                <Paper className="statics">
                    <div className='header'>
                        <h1 className='title'>Obras sociales</h1>
                    </div>
                    {
                        valuesListOs ? 
                        <ChartsForOs labels={os} values={valuesListOs} />
                        :
                        <CircularProgress/>
                        
                    }                    
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}> 
                <Paper className="statics">
                    <div className='header'>
                        <h1 className='title'>Turnos este mes</h1>
                    </div>
                    {
                        valuesListShifts ?
                        <ChartsForShifts labels={listStates} values={valuesListShifts} />
                        :
                        <CircularProgress/>

                    }
                </Paper>
            </Grid>
        </Grid>
    )
}
