import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { patientGetDetail } from '../actions/patient';
import { PatientDataDetail } from './PatientDataDetail';
import { PatientTimeline } from './PatientTimeline';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
  

export const PatientDetail = ( props ) => {
    const dispatch = useDispatch();
    const patientId = props.match.params.id;
    const { patientDetail } = useSelector(state => state.patient)

    useEffect(() => {
        dispatch(patientGetDetail(patientId));
    }, [])

    return (
        <div>            
            { patientDetail ? <PatientDataDetail patient={patientDetail} /> : 
                <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '50vh' }}
              >              
                <Grid item xs={3}>
                  <CircularProgress />
                </Grid>                 
              </Grid>
            }
            { patientDetail && patientDetail.shifts.length > 0 ? 
                <PatientTimeline shifts={patientDetail.shifts} /> 
                : 
                <h1 className="empty-historial">AÚN NO ASISTIÓ A UNA CONSULTA</h1>
            }
            
        </div>
    )
}
