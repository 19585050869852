import { types } from '../types/types';

const initialState = {
    checking: true,
    loginErrors: null,
    firstName: ''
    //uid: null,
    //email: null
};


export const authReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.authLogin:
            return {
                ...state,
                checking: false,
                loginErrors: null,
                firstName: action.payload.firstName,
                ...action.payload
            }

        case types.authCheckingFinish:
            return {
                ...state,
                loginErrors: null,
                checking: false
            }

        case types.authLoginFail:
            return {
                ...state,
                loginErrors: action.payload
            }

        case types.authSatrtLogin:
            return {
                ...state,
            }
            
        case types.authLogout:
            return {
                checking: false,
                loginErrors: null
            }

        default:
            return state;
    }
};