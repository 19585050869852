import { types } from "../types/types";
import { fetchAuth } from "../helpers/fetch";
import { prepareShifts } from "../helpers/prepareShifts";
import Swal from 'sweetalert2';
import moment from 'moment'

export const shiftStartAddNew = (shift) => {
    return async (dispatch, getSate) => {
        
        const { uid, name } = getSate().auth;
        try {
            const resp = await fetchAuth('shift/',shift, 'POST');
            const body = await resp.json();

            if(body.ok){

                shift.id = body.shift.id;
                shift.user = {
                    _id: uid,
                    name: name
                };

                const newShift = prepareShifts( [body.shift] );
                //cambiar body.shift por shift
                dispatch(shiftAddNew( newShift[0]));

                Swal.fire({
                    title: '<p>Exito</p>',
                    icon: 'success',
                    text: 'Turno registrado'
                });

            }else{
                Swal.fire({
                    title: '<p>Error</p>',
                    icon: 'error',
                    text: body.message 
                });
            }
            
        } catch (error) {
            
            console.log(error);
        }

        
    }
};

const shiftAddNew = (shift) => ({
    type: types.shiftAddNew,
    payload: shift
});


export const shiftSetActive = (shift) => ({
    type: types.shiftSetActive,
    payload: shift
});


export const shiftActiveClear = () => ({
    type: types.shiftActiveClear
});

export const shiftStartUpdate = (shift) => {
    return async (dispatch) => {
        console.log(shift);
        try {
            const resp = await fetchAuth(`shift/${shift.id}`, shift, 'PUT');
            const body = await resp.json();

            if (body.ok) {
                const newShift = prepareShifts( [body.shift] );                
                Swal.fire({
                    title: '<p>Exito</p>',
                    icon: 'success',
                    text: body.message
                });
                dispatch( shiftUpdate(newShift[0]));
            }else{
                Swal.fire({
                    title: '<p>Error</p>',
                    icon: 'error',
                    text: body.message
                });
                console.log("Error al actualizar", body.message);
            }
        } catch (error) {
            console.log(error);
        }
    }
};

const shiftUpdate = (shift) => ({
    type: types.shiftUpdate,
    payload:  shift
});

export const shiftStartDelete = () => {
    return async ( dispatch, getState ) => {

        const { id } = getState().calendar.activeShift;

        try {
            const resp = await fetchAuth(`shift/${ id }`, {}, 'DELETE');
            const body = await resp.json();

            if (body.ok) {
                Swal.fire({
                    title: '<p>Exito</p>',
                    icon: 'success',
                    text: body.message
                });
                dispatch( shiftDeleted());
            }else{
                Swal.fire({
                    title: '<p>Error</p>',
                    icon: 'error',
                    text: body.message
                });
                console.log("Error al eliminar", body.message);
            }
        } catch (error) {
            console.log(error);
        }
    }
};

const shiftDeleted = () => ({ type: types.shiftDeleted });

export const shiftDeletedWhenPatientDeleted = (patientId) => ({
    type: types.shiftDeletedWhenPatientDeleted,
    payload: patientId
});

const actualYear  = moment().format('YYYY');
const actualMonth = moment().format('MM');
//get all shifts 
export const shiftStartLoading = ({month = actualMonth, year = actualYear, ownerId = ''} = {}) => {

    return async (dispatch, getSate) => {
        const { shiftQueue } = getSate().calendar;
        const date = month + "-" + year;
        if (!shiftQueue.includes(date)) {
            dispatch(shiftQueueControl(date));
            try {
                const resp = await fetchAuth(`shift/all?month=${month}&year=${year}&ownerId=${ownerId}`);
                const body = await resp.json();
    
                const shifts = prepareShifts( body.shifts );
    
                dispatch( shiftLoaded(shifts));
            } catch (error) {
                console.log(error);
            }
        }
        
    }
};

const shiftLoaded = (shifts) => ({
    type: types.shiftLoaded,
    payload: shifts
});

export const shiftLogout = () => ({
    type: types.shiftLogout
});

export const shiftClearAll = () => ({
    type: types.shiftClearAll
});

const shiftQueueControl = (date) => {
    return ({
        type: types.shiftQueue,
        payload: date
    });
};

export const shiftClearQueue = () => {
    return ({
        type: types.shiftClearQueue
    });
};