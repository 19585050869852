import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';

//data picker for the birthdate
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { es } from "date-fns/locale";

import { fetchAuth } from '../../helpers/fetch';
//---------------------------------------------------------------
import { shiftUnsetForm, uiCloseModal, uiOpenModal, uiOpenPatientFormModal } from '../../actions/ui';
import { shiftActiveClear, shiftStartUpdate, shiftStartAddNew, shiftSetActive } from '../../actions/shifts';
import Swal from 'sweetalert2';
import { PatientFormModal } from '../../patients/formModal';
import { ObservationsHistory } from './ObservationsHistory';

//initial form new patient data values
const initialForm = {
  address: '',
  dni: '',
  email: '',
  birthDate: new Date('2015-08-18'),
  firstName: '',
  lastName: '',
  telephone: '',
  country: '',
  province: '',
  state: '',
  observations: '',
  osId: '1',
  osNumber: '',
  start: '',
  end:''
};




export const CalendarModal = () => {

    const { modalOpen, shift_start, shift_end } = useSelector( state => state.ui);
    const { activeShift } = useSelector( state => state.calendar);
    const { selectedMedic } = useSelector( state => state.user);
    const { role } = useSelector(state => state.auth);
    const listOs = useSelector(state => state.os.os);
    const dispatch = useDispatch();

    const [searchButton, setsearchButton] = useState(true);
    const [activeInputs, setInactiveInputs] = useState(true);
    const [shiftState, setShiftState] = useState(false);

    
    //patient shifts state
    const toggleChecked = () => {      
      setShiftState((prev) => !prev);
      if (!shiftState) {
        setFormValues({...formValues, state: 3}); //absent
      }else{
        setFormValues({...formValues, state: 2}); //in progress
      }
    };
  
    //search patient
    const [searchingPatient, setSearchingPatient ] = useState({
      loading: false
    });

    const searchPatient = async (dni) => {
    
      setSearchingPatient({loading: true});
      const resp =  await fetchAuth('patient/find/'+ dni);
      const body =  await resp.json();
        
      if (body.ok) {
        setSearchingPatient({loading: false}); 
        body.patient.os = body.patient.os.name;//get the os name for that patient
        delete body.patient.shifts; //remove shift historial
        if (activeShift) {
          //if come frome an active shift, load extra data
          setFormValues({
            ...body.patient, 
            id: activeShift.id,
            patientId: body.patient.id,
            state: activeShift.state,
            observations: activeShift.observations});
          }else{
            setFormValues({...body.patient, patientId: body.patient.id});
          }
          
        setSelectedOs(body.patient.osId);   
      }else{
        Swal.fire({
          title: '<p>¡UPS!</p>',
          icon: 'error',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Aceptar',
          html: 'No se encontro un paciente con ese DNI. <br> ¿Desea registrarlo?',        
        })
        .then( result => {
          if (result.isConfirmed) {
            dispatch( uiCloseModal());
            dispatch( uiOpenPatientFormModal());
          }
          
        });
        //reset Form
        setSelectedOs(1);
        setSearchingPatient({loading: false});   
              
      }
  
      //show dialog session expire
      if (body.message === 'jwt expired' || body.message === 'invalid signature') {
        setOpenAlert(true);
      }      
      
    };

    
    //form
    const [formValues, setFormValues] = useState({
        lastName: '',
        address: '',
        dni: '',
        email: '',
        birthDate: new Date('1990-08-18'),
        firstName: '',
        telephone: '',
        country: '',
        province: '',
        state: '',
        observations: '',
        osId: '',
        osNumber: '',
    });

    const { lastName, address, dni, email,
            birthDate,  firstName, telephone,
            country, province, state, observations, 
            osId,  osNumber } = formValues;

    

    useEffect(() => {
      if( activeShift ){
        setSelectedOs(activeShift.osId);
        setFormValues( activeShift );
        //check if patient was absent
        if (activeShift.state === 3) {
          setShiftState(true);
        }
      }else{
        //if form open for new patient, clear it
        setSelectedOs(1);
        setFormValues( initialForm );
        setShiftState(false);
      }

    }, [activeShift, setFormValues, setSearchingPatient]);

    const handleInputChange = ({ target }) => {
        //search patient by dni
        if (target.name === 'dni' && target.value.length >= 6) {
          setsearchButton(false);
          //setSearchingPatient({loading: true});
          //searchPatient(target.value);
        }else{
          setsearchButton(true);
        }
        
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }

  
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        //editing one or adding new shift
        //ownerId is only seted when the user is a secretary
        if ( activeShift ) {
          
          dispatch( shiftStartUpdate( {...formValues, 
            osId: selectedOs, 
            os: listOs[selectedOs]?.name,
              ownerId: selectedMedic
            }));

        }else{
          //new shift
          dispatch(shiftStartAddNew({
            ...formValues,
            start: shift_start,
            end: shift_end,
            osId: selectedOs,
            os: listOs[selectedOs -1].name.toString(),
            ownerId: selectedMedic,
          }));

        }
        
        //if sent ok, then close modal      
        dispatch( uiCloseModal());
        //clear form
        setFormValues(initialForm);
        //clear start end date from store
        dispatch(shiftUnsetForm());
        //unset active event from store
        dispatch(shiftActiveClear());
        
    }
    //end form

    const handleClose = () => {
      dispatch( uiCloseModal());
      setFormValues(initialForm);
      dispatch(shiftActiveClear());
      setInactiveInputs(true);
      setsearchButton(false);
      setShiftState(false);
    };

    //datapicker for born date
    const [selectedDate, setSelectedDate] = React.useState(new Date('2015-08-18'));

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setFormValues({
            ...formValues,
            birthDate: selectedDate
        });
    };

    //os select    
    const [selectedOs, setSelectedOs] = React.useState(1);

    const handleOsChange = (event) => {
      setSelectedOs(event.target.value);
      setFormValues({...formValues, osId: selectedOs});
    };

    //dialog
    const [openAlert, setOpenAlert] = React.useState(false);
     
    const handleCloseAlert = () => {
      setOpenAlert(false);
      window.location.reload();
    };

    return (
      <div>
        <Dialog 
          open={ modalOpen } 
          onClose={handleClose} 
          aria-labelledby="form-dialog-title" 
          fullScreen={true}>
        <DialogTitle id="form-dialog-title">{ (activeShift) ? 'Editar Turno' : 'Nuevo Turno' }</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          <form onSubmit={handleFormSubmit}>
            <Grid container alignContent="center" justify="center"  spacing={5}>
              <Grid item lg={6}>
                <h1>Datos del paciente</h1>
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={10}>
                        <FormGroup>
                          <TextField name="dni" value={dni} onChange={handleInputChange} label="DNI" variant="outlined" type="number" />
                              { (searchingPatient.loading) && <CircularProgress/> }  
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={()  => {searchPatient(dni)}}
                                endIcon={<Icon>search</Icon>}
                                disabled={searchButton}        
                                >
                                Buscar paciente
                            </Button>
                          </FormGroup> 
                      </Grid>
                      <Grid item xs={2}>
                        <FormControlLabel
                          control={<Switch checked={shiftState} onChange={toggleChecked} />}
                          label="Ausente"
                          labelPlacement="top"
                        />
                      </Grid>  
                    </Grid>                    
                  </Grid>

                  <Box margin={2}></Box>
                  <Grid item xs={12}>
                    <Grid container  spacing={2} >
                      <Grid item xs={12} lg={6}>
                        <FormGroup> 
                          <TextField value={lastName} onChange={handleInputChange} name="lastName" label="Apellido" variant="outlined" disabled={activeInputs}/>
                          <TextField value={firstName} onChange={handleInputChange} name="firstName" label="Nombre" variant="outlined" disabled={activeInputs}/>
                          <TextField value={telephone} onChange={handleInputChange} name="telephone" label="Telefono" variant="outlined" disabled={activeInputs}/>
                          <TextField value={province} onChange={handleInputChange} name="province" label="Provincia" variant="outlined" disabled={activeInputs}/>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es} >
                              <KeyboardDatePicker
                                disabled={activeInputs}
                                animateYearScrolling="true"
                                disableFuture="true"
                                variant="inline"
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                name="birthDate"
                                label="Fecha de Nacimiento"
                                value={birthDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </FormGroup> 
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <TextField value={email} onChange={handleInputChange} name="email" label="Email" variant="outlined" disabled={activeInputs}/>
                          <TextField value={address} onChange={handleInputChange} name="address" label="Dirección" variant="outlined" disabled={activeInputs}/>
                          <TextField value={country} onChange={handleInputChange} name="country" label="Pais" variant="outlined" disabled={activeInputs}/>
                          <Select
                            disabled={activeInputs}
                            label="OS"
                            name="osId"
                            variant="outlined"
                            value={selectedOs}
                            onChange={handleOsChange}
                            >                  
                            {
                              listOs.map( (e, key) => {
                                return <MenuItem key={key} value={e.id}>{e.name}</MenuItem>;
                              })
                            }
                          </Select>
                          <Box m={1.5} />
                          <TextField value={osNumber} onChange={handleInputChange} name="osNumber" label="N°" variant="outlined" disabled={activeInputs}/>
                        </FormGroup>
                      </Grid>                      
                      <input value={shift_start} onChange={handleInputChange} name="start" label="N°" type="hidden"  variant="outlined" disabled={activeInputs}/>
                      <input value={shift_end} onChange={handleInputChange} name="end" label="N°" type="hidden" variant="outlined" disabled={activeInputs}/>

                    </Grid>

                  </Grid>
                  
                </Grid> 

              </Grid>


              <Grid item lg={6}>
                <h1>Historial</h1>
                <TextField
                    id="outlined-multiline-static"
                    fullWidth
                    label="Observaciones"
                    multiline
                    rows={4}
                    name="observations"
                    value={observations}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                  {
                    activeShift  &&
                    <ObservationsHistory history={ activeShift ? activeShift?.patientHistory : [] } />
                  }
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleFormSubmit} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">La sesión expiró</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Inicie sesión nuevamente
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseAlert} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <PatientFormModal/>

      </div>
    )
}
