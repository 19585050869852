import { types } from "../types/types";



export const uiOpenModal = () => ({
    type: types.uiOpenModal
});

export const uiCloseModal = () => ({
    type: types.uiCloseModal
});

export const shiftSetForm = (date) => ({
    type: types.shiftSetForm,
    payload: date
});

export const shiftUnsetForm = () => ({
    type: types.shiftUnsetForm
});


export const uiOpenPatientFormModal = (patientData) => ({
    type: types.uiOpenPatientFormModal,
    payload: patientData
});

export const uiClosePatientFormModal = () => ({
    type: types.uiClosePatientFormModal
});

export const uiPatientFormErrors = (errors) => ({
    type: types.uiPatientFormErrors,
    payload:  errors
});

export const uiPatientFormErrorsReset = () => ({
    type: types.uiPatientFormErrorsReset
});

export const uiPatientFormValidated = (validate) => ({
    type: types.uiPatientFormValidated,
    payload: validate
});
