import { types } from '../types/types';

const initialState = {
    listStates: null,
    valuesListShifts: null,
    os: null,
    valuesListOs: null
    
};


export const staticsReducer = ( state = initialState, action ) => {

    switch (action.type) {
        case types.staticsGeneral:
            const { listStates, valuesListShifts, os, valuesListOs } = action.payload;
            return {
                listStates,
                valuesListShifts,
                os,
                valuesListOs,
            }
    
        default:
            return state;
    }
}