import { fetchPublic, fetchAuth } from '../helpers/fetch';
import { types } from '../types/types';
import { shiftLogout } from './shifts';
import { userStartGetPreferences } from './user';


export const startLogin = (email, password) => {
    //como es asincrona se usa el return para que thunk re lanze la accion
    return async ( dispatch ) => {
        
        const resp =  await fetchPublic('auth/login',{email, password}, 'POST');
        const body =  await resp.json();
        
        if(body.token){
            localStorage.setItem('token', body.token);
            localStorage.setItem('token-init-date', new Date().getTime());
            
            dispatch( login({
                uid: body.userId,
                firstName: body.firstName,
                role: body.role
            }));
            //load user preferences            
            dispatch( userStartGetPreferences());
            
        }else{
            //if login fail
            dispatch( loginFail(body.message));
        }
    }
};


const login = ( user ) => ({
    type: types.authLogin,
    payload: user
});

const loginFail = ( errors ) => ({
    type:  types.authLoginFail,
    payload: errors
});


export const startChecking = () => {
    return async (dispatch) => {
        
        try {
            const resp =  await fetchAuth('auth/renew');
            const body =  await resp.json();

            if(body.token){
                localStorage.setItem('token', body.token);
                localStorage.setItem('token-init-date', new Date().getTime());
                
                dispatch( login({
                    uid: body.userId,
                    firstName: body.firstName,
                    role: body.role
                }));
    
                //load user preferences            
                dispatch( userStartGetPreferences());
    
            }else{
                //if login fail
                dispatch( loginFail(body.message));
                dispatch(checkingFinish());
                console.log('Login error ', body.message);
            }
        } catch (error) {
            console.log("Fetch Error: al intentar logear", error);
        }
        
        
    }
};

const checkingFinish = () => ({
    type: types.authCheckingFinish
});

export const startLogout = () => {
    return ( dispatch) =>{
        localStorage.removeItem('token');
        localStorage.removeItem('token-init-date');
        dispatch(shiftLogout());
        dispatch(logout());
    };
}

const logout = () => ({ type: types.authLogout });