import React from 'react'
import { Provider } from 'react-redux';
import { AppRouter } from './router/AppRouter'
import { withStyles } from '@material-ui/core/styles';


import { store } from './store/store';

const GlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    '@global': {
      // You should target [class*="MuiButton-root"] instead if you nest themes.
      '.MuiButton-root': {
        //fontSize: '1rem',
      },
      '.MuiFormControl-root': {
          marginBottom: '10px !important',
          '&:focused':{
            color: 'red'
          }
      },
      'body': {
          margin:0,
      },
      '#root':{
        
      }
    },
  })(() => null);

export const App = () => {
    return (
        <Provider store={store}>
             <GlobalCss />
            <AppRouter/>
        </Provider>
    )
}
