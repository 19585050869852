import { types } from '../types/types';

// TODO: set in db particular os
const initialState = { 
    os: []
};

export const osReducer = ( state = initialState, action) => {

    switch (action.type) {
        case types.osGetAll:
            return {...state};
        
        case types.osAllLoaded:
            return {
                ...state,
                os: [...action.payload]
            };
        default:
            return state;
    }
};