import moment from 'moment';

export const getDatesBetween = (startDate, endDate) => {
    const dates = [];

        // Strip hours minutes seconds etc.
        let currentDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
        );
        //get dates between start and end
        while (currentDate <= endDate) {
            dates.push(moment(currentDate).format('DD/MM/YYYY'));
    
            currentDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() + 1, // Will increase month if over range
            );
        }
        
        return dates;
}