import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
       
    shiftDetail: {
        marginTop: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    }
}));

export const CalendarEvent = ({ event }) => {
    const classes = useStyles();

    let shiftState = 'pendiente';


    const { patient, state } = event;

    switch (state) {
        case 1:
            shiftState = 'pendiente';
            break;
        case 2:
            shiftState = 'en-proceso';
            break;
        case 3:
            shiftState = 'ausente';
            break;
        case 4:
            shiftState = 'finalizado';
            break;
    
        default:
            break;
    }

    return (
        <div className={`shift ${shiftState}`}>
            <strong>{patient.lastName }, { patient.firstName}</strong>
            <div className="shift-detail">
                <span>
                    <i>{ patient.os.name }</i>
                </span>
                <span className={`shift-state ${classes.pending}`}>
                    {shiftState}
                </span>
            </div>
        </div>
    )
}
