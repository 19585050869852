import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import moment from 'moment';
import { useSelector } from 'react-redux';

export const ObservationsHistory = ({ history }) => {

    const activeShift = useSelector(state => state.calendar.activeShift);   

    if (!history || !history.length > 0) {
        return (<h1>No hay historial</h1>);
    }

  

    return (
        <div>
            {
                history.map( (shift, key) => {
                  if (shift.id === activeShift.id) {
                    return ;
                  }
                    return (
                    <Accordion key={key}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p>
                          <Typography variant="button">{moment(shift.start).format('D MMMM YYYY')} </Typography>
                          <Typography variant="caption" color="secondary"> ({moment(shift.start).fromNow()})</Typography>
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="body2">
                            <pre style={{ fontFamily: 'inherit' }}>
                              { shift.observations }
                            </pre>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                })
            }
        </div>
    )
}
