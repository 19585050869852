import { fetchAuth } from './fetch';

//get shift history for a patient by dni
export const getPatientHistory = async (dni) => {
        
    const resp =  await fetchAuth('patient/find/'+ dni);
    const body =  await resp.json();
      
    if (body.ok) {
       const shifts = body.patient.shifts;
      //order DESC by id 
      const patientHistory = shifts.sort( (a,b) => {
        let comparison = 0;
        if (a.id > b.id) {
          comparison = 1;
        }else if (a.id < b.id){
          comparison = -1;
        }
 
        return comparison * -1;
      })
      return patientHistory;     
    }else{     
      return [];
    }
            
}