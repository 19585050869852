import React from 'react';
import { Pie } from '@reactchartjs/react-chart.js';

export const ChartsForOs = ({ labels, values }) => {

     
    const data = {
        labels: labels,
        datasets: [
          {
            label: '# of Votes',
            data: values,
            backgroundColor: [
              'rgb(72, 61, 139, 0.2)',
              'rgba(139, 0, 139, 0.2)',
              'rgba(148, 0, 211, 0.2)',
              'rgba(0, 0, 205, 0.2)',
              'rgba(0, 128, 128, 0.2)',
              'rgba(0, 255, 255, 0.2)',
              'rgba(100, 149, 237, 0.2)',
              'rgba((95, 158, 160, 0.2)',
              'rgba(27, 255, 212, 0.2)',
              'rgba(144, 238, 144, 0.2)',
              'rgba(139, 139, 139, 0.2)',
              'rgba(201, 253, 188, 0.2)',
            ],
            borderColor: [
                'rgb(72, 61, 139, 1)',
                'rgba(139, 0, 139, 1)',
                'rgba(148, 0, 211, 1)',
                'rgba(0, 0, 205, 1)',
                'rgba(0, 128, 128, 1)',
                'rgba(0, 255, 255, 1)',
                'rgba(100, 149, 237, 1)',
                'rgba((95, 158, 160, 1)',
                'rgba(27, 255, 212, 1)',
                'rgba(144, 238, 144, 1)',
                'rgba(139, 139, 139, 1)',
                'rgba(201, 253, 188, 1)',
            ],
            borderWidth: 1,
          },
        ],
      }

    return (
        <>
            <Pie data={data} />
        </>
    )
}
