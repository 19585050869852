import { types } from "../types/types";
import { fetchAuth } from "../helpers/fetch";


export const osGetAll = () => {
    return async (dispatch) => {
        try {
            const resp =  await fetchAuth('os/all');
            const body =  await resp.json();
            
            dispatch(osAllLoaded(body.os));

        } catch (error) {
            console.log(error);
        }
    }
};

const osAllLoaded = (allOs) => ({
    type: types.osAllLoaded,
    payload: allOs
});