import { types } from '../types/types';

const initialState = {
    preferences: [],
    blockDays: [],
    medics: [],
    selectedMedic: null
};


export const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.userGetPreferences:            
            return {
                ...state,
                preferences:action.payload.preferences,
                blockDays: action.payload.blockdays
            }

        case types.userRemoveBlockedDate:
            return {
                ...state,
                blockDays: action.payload
            }

        case types.userBlockDate:
            return {
                ...state,
                blockDays: action.payload
            }

        case types.userGetMedics:
            return {
                ...state,
                medics: action.payload
            }
        case types.userSelectMedic:
            return {
                ...state,
                selectedMedic: action.payload
            }        
        default:
            return state;
    }
};