import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteIcon from '@material-ui/icons/Delete';
import { DateRange } from 'react-date-range';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import { es } from 'date-fns/locale'
import { Button, Grid, Typography } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { getDatesBetween } from '../../helpers/getDatesBetween';
import { useDispatch, useSelector } from 'react-redux';
import { usertStartBlockDate, usertStartRemoveBlockedDate } from '../../actions/user';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });


export const BlockDay = () => {
    const { blockDays } = useSelector(state => state.user)
    const [selectedDate, setSelectedDate] = React.useState(moment().format());
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        
    }, [blockDays, loading])

  
    const handleOpen = () => {
        setOpen(true);
    }
    
    const deleteBlockedDay = async ({ blockedDay }) => {
        setLoading(true);
        await dispatch(usertStartRemoveBlockedDate(blockedDay));
        setLoading(false);

    }

    //date range picker
    const rangeInitialState = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    };

    const [range, setRange] = React.useState([
        rangeInitialState
    ]);  

    const handleClose = (date) => {
        setOpen(false);
        setRange([rangeInitialState]);
    };

    //handle dates range
    const handleSelect = (ranges) => {
        setRange([ranges.selection]);// native Date object
    }

    const blockDate = () => {
        setLoading(true);
        const { startDate, endDate } = range[0];       
        const datesToBlock = getDatesBetween(startDate, endDate);
        //dispatch dates for block
        dispatch(usertStartBlockDate(datesToBlock));
        setRange([rangeInitialState]);
        setLoading(false);        
    }

    return (
        <div>
            <Grid container direction="row" alignItems="flex-start" className="block-day-button">
                <Grid item md={3}>
                    <Button
                        variant="contained" 
                        color="secondary"
                        onClick={handleOpen}
                    >
                        Bloqueo de Fechas
                    </Button>
                </Grid>
            </Grid>          
            <Dialog
                className="block-date-modal"                
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                maxWidth="md"
                fullWidth={true}
                >
                <DialogContent>
                    <Grid container direction="row" alignItems="flex-start" >
                        <Grid item md={6}>
                            <Typography variant="h5">Fechas Bloqueadas</Typography>
                            {
                                (blockDays) && 
                                blockDays?.map( blockedDay => (
                                    <Grid key={blockedDay} container direction="row" alignItems="center">
                                        <IconButton onClick={() => { deleteBlockedDay({blockedDay}) }} aria-label="delete">
                                            <DeleteIcon fontSize="medium" />
                                        </IconButton>
                                        <Typography>{blockedDay}</Typography>
                                    </Grid>
                                    )
                                )
                                
                            }
                            
                        </Grid>

                        <Grid item md={6}>
                            <Typography variant="h5">Bloquear nueva fecha</Typography>
                            <DateRange
                                locale={ es }
                                ranges={range}
                                showDateDisplay={true}
                                onChange={handleSelect}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                            />
                            <Button
                                color="secondary"
                                disabled={loading}
                                onClick={blockDate}
                                style={{marginLeft: '1rem'}}
                                variant="contained"
                            >
                                Bloquear
                            </Button>
                            {loading && <CircularProgress color={'secondary'} />}
                        </Grid>                       
                    </Grid>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => { handleClose(selectedDate) }} color="primary" autoFocus>
                    Aceptar
                </Button>
                </DialogActions>
            </Dialog>            
        </div>
    )
}
