import { types } from "../types/types";
import { fetchAuth } from "../helpers/fetch";
import Swal from 'sweetalert2';
import { uiClosePatientFormModal, 
        uiPatientFormErrors, 
        uiPatientFormErrorsReset, 
        uiPatientFormValidated} from '../actions/ui';
import { shiftDeletedWhenPatientDeleted } from '../actions/shifts';


export const patientGetAll = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchAuth('patient/');
            const body = await resp.json();

            if(body.ok){
                dispatch(patientAllLoaded(body.patientsList));
            }else{
                Swal.fire({
                    title: '<p>Error</p>',
                    icon: 'error',
                    text: body.message
                });
            }

        } catch (error) {
           console.log(error);
        }
    }
}

const patientAllLoaded = (allPatients) => ({
    type: types.patientsAllLoaded,
    payload: allPatients
});

export const patientGetDetail = ( id ) => {
    return async (dispatch) => {
        try {
            const resp = await fetchAuth(`patient/${id}/shifts`);
            const body = await resp.json();

            if(body.ok){
                dispatch(patientDetailLoaded(body.patient));
            }else{
                Swal.fire({
                    title: '<p>Error</p>',
                    icon: 'error',
                    text: body.message
                });
            }

        } catch (error) {
           console.log(error);
        }
    }
}

const patientDetailLoaded = (patientDetail) => ({
    type: types.patientDetailLoaded,
    payload: patientDetail
});


export const patientStartAddNew = (patient) => {
    return async (dispatch) => {
        
        try {
            const resp = await fetchAuth('patient/',patient, 'POST');
            const body = await resp.json();

            if(body.ok){
                Swal.fire({
                    title: '<p>Exito</p>',
                    icon: 'success',
                    text:  body.msg
                });
                const newPatient = body.patient;
                dispatch(patientAddNew( newPatient));
                dispatch(uiPatientFormErrorsReset());
                dispatch(uiPatientFormValidated(true));
                dispatch(uiClosePatientFormModal());
                
            }else{
                const errors = patientValidate(body.data);
                dispatch(uiPatientFormErrors(errors));
                dispatch(uiPatientFormValidated(false));
                Swal.fire({
                    title: '<p>Error</p>',
                    icon: 'error',
                    text:  'Verifique los datos ingresados' //body.data[0].msg
                });
            }
            
        } catch (error) {
            
            console.log(error);
        }        
    }
};

const patientAddNew = (patient) => ({
    type: types.patientAddNew,
    payload: patient
});

export const patientSetActive = (patient) => ({
    type: types.patientSetActive,
    payload: patient
});


export const patientActiveClear = () => ({
    type: types.patientActiveClear
});

export const patientStartDelete = () => {
    return async ( dispatch, getState ) => {

        const { id } = getState().patient.activePatient;
        console.log("active patient id ", id);

        try {
            const resp = await fetchAuth(`patient/${ id }`, {}, 'DELETE');
            const body = await resp.json();

            if (body.ok) {
                Swal.fire({
                    title: '<p>Exito</p>',
                    icon: 'success',
                    text: body.message
                });
                dispatch( shiftDeletedWhenPatientDeleted( id ) );
                dispatch( patientDeleted());
            }else{
                Swal.fire({
                    title: '<p>Error</p>',
                    icon: 'error',
                    text: body.message
                });
                console.log("Error al eliminar", body.message);
            }
        } catch (error) {
            console.log(error);
        }
    }
};

const patientDeleted = () => ({ type: types.patientDeleted });

export const patientStartUpdate = (patient) => {
    return async (dispatch) => {
        try {
            const resp = await fetchAuth(`patient/${patient.id}`, patient, 'PUT');
            const body = await resp.json();

            if (body.ok) {              
                Swal.fire({
                    title: '<p>Exito</p>',
                    icon: 'success',
                    text: body.message
                });
                dispatch( patientUpdate(body.patient));
                dispatch( uiPatientFormErrorsReset() );
                dispatch( uiPatientFormValidated(true)  );
                dispatch( uiClosePatientFormModal());
            }else{
                const errors = patientValidate(body.data);
                dispatch( uiPatientFormErrors(errors) );
                dispatch( uiPatientFormValidated(false) );
                Swal.fire({
                    title: '<p>Error</p>',
                    icon: 'error',
                    text:  'Verifique los datos ingresados' //body.data[0].msg
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
};

const patientUpdate = (patient) => ({
    type: types.shiftUpdate,
    payload:  patient
});


//group validation errors per input
const patientValidate = (errors) => {
    let inputErrors = {};
    errors.map( error => {
        if (inputErrors[error.param+'Error']) {
            return inputErrors[error.param+'Error'] += ', ' + error.msg;
        }else{  
            return inputErrors[error.param+'Error'] = error.msg;
        }
    });

    return inputErrors;
}



