import { startOfMinute } from 'date-fns';
import { types } from '../types/types';


const initialState = {
    activePatient: null, 
    patients: [],
    patientDetail: null
};

export const patientReducer = ( state = initialState, action) => {

    switch (action.type) {
        case types.patientGetAll:
            return {...state};
        
        case types.patientsAllLoaded:
            return {
                ...state,
                patients: [...action.payload]
            }
        case types.patientAddNew:
            return {
                ...state,
                patients: [
                    ...state.patients,
                    action.payload
                ]
            }
        case types.patientSetActive:
            return {
                ...state,
                activePatient: action.payload
            } 
        case types.patientActiveClear: 
            return {
                ...state,
                activePatient: null
            }

        case types.patientUpdate:
            return {
                ...state,
                patients: state.patients.map(
                    patient => ( patient.id === action.payload.id) ? action.payload : patient
                )
            }

        case types.patientDeleted:
            return {
                ...state,
                patients: state.patients.filter(
                    patient => patient.id !== state.activePatient.id
                ),
                activeShift: null
            }

        case types.patientDetailLoaded:
            return {
                ...state,
                patientDetail: action.payload
            }

        default:
            return state;
    }
};