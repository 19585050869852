import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import { useDispatch, useSelector } from 'react-redux';
import { patientGetAll } from "../actions/patient";
import { uiOpenPatientFormModal } from "../actions/ui";

import { MenuDataGrid } from '../components/ui/MenuDataGrid';
import { Box } from '@material-ui/core';
import { PatientFormModal } from './formModal';

const columns = [
    //{ field: 'id', headerName: 'ID', width: 70 },
  { field: 'dni', headerName: 'DNI', width: 100 },
  { field: 'lastName', headerName: 'Apellido', width: 200 },
  { field: 'firstName', headerName: 'Nombre', width: 200 },
  {
    field: 'age',
    headerName: 'Edad',
    type: 'number',
    width: 90,    
  },  
  { 
    field: 'os', 
    headerName: 'Obra Social', 
    width: 400,
  },
  {
      field: 'id',
      headerName: 'Editar',
      sortable: false,
      renderCell: (params) => {
        return (
            <MenuDataGrid patientId={params.value} />
        );
      }
  }
];


export const PatientsScreen = () => {
    const dispatch = useDispatch();
    const { patients } = useSelector(state => state.patient)
    const [loading, setLoading] = React.useState(true);

    const checkLoading = () => {
        if (patients) {
            setLoading(false);
        }
    }
    
    useEffect(() => {
        dispatch(patientGetAll());
        checkLoading();
    }, [dispatch]);

    
    const craetePatient = () => {
      dispatch( uiOpenPatientFormModal());
    }


    return (
    <>
    <Button
      variant="contained"
      className="add-patient-button"
      startIcon={<Icon>group</Icon>}
      onClick={ craetePatient}
    >
        Nuevo Paciente
    </Button>
    <Box pb={2} />
    <div style={{ minHeight: 650, width: '100%' }}>
        <DataGrid 
            autoHeight={true}
            loading={loading}
            rows={patients} 
            columns={columns} 
            pageSize={10} 
            disableMultipleSelection={true} />
     
    </div>
    <PatientFormModal/>
    </>
    )
}
