import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    BrowserRouter as Router,
    Switch,
    Redirect
  } from "react-router-dom";
import { LoginScreen } from '../components/auth/LoginScreen';
import {DashboardScreen} from '../components/dashboard/DashboardScreen'
import { startChecking } from '../actions/auth'
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ForgotPassword } from '../components/auth/ForgotPassword';
import { ResetPassword } from '../components/auth/ResetPassword';
  

export const AppRouter = () => {

    const dispatch = useDispatch();
    const { checking, uid } = useSelector(state => state.auth);
    //chek if login
    useEffect(() => {
      dispatch(startChecking());
    }, [dispatch])
    
    if (checking) {
      return (<Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
      
        <Grid item xs={3}>
          <CircularProgress />
        </Grid>   
      
      </Grid>);
    }

    return (
        <Router>
        <div>
          <Switch>
            <PublicRoute 
              exact 
              path="/login" 
              component={LoginScreen}
              isAuthenticated={!!uid}
              />
            <PublicRoute 
              exact 
              path="/forgot-password" 
              component={ForgotPassword}
              isAuthenticated={!!uid}
            />

            <PublicRoute 
              exact 
              path="/reset-password" 
              component={ResetPassword}
              isAuthenticated={!!uid}
            />

            <PrivateRoute 
              exact 
              path="/dashboard" 
              component={DashboardScreen}
              isAuthenticated={!!uid}
              />

            <Redirect to="/login" />
          </Switch>
        </div>
      </Router>
    )
}
