import { types } from '../types/types'

const initialPatientForm = {
    address: '',
    dni: '',
    email: '',
    birthDate: new Date('2000-01-01'),
    firstName: '',
    lastName: '',
    telephone: '',
    country: '',
    province: '',
    state: '',
    observations: '',
    osId: 1,
    os: '',
    osNumber: '',
    start: '',
    end:''
  }

const initialSate = {
    modalOpen: false,
    shift_start: '',
    shift_end: '',
    //--- patient form
    patientFormModalOpen: false,
    initialPatientForm,
    patientFormErrors: {},
    patientFormValidate: false

}

export const uiReducer = ( state = initialSate, action) => {

    switch (action.type) {
        case types.uiOpenModal:
            return {
                ...state,
                modalOpen: true
            }
        
        case types.uiCloseModal:
            return {
                ...state,
                modalOpen: false
            }    
            
        case  types.shiftSetForm:
            return {
                ...state,
                shift_start: action.payload.start,
                shift_end: action.payload.end
            }
        
        case types.shiftUnsetForm:
            return {
                ...state,
                shift_start: '',
                shift_end: ''
            }
        //for patient screens
        case types.uiOpenPatientFormModal:
            //check if come from edit patient or create
            let patientData = action.payload;
            if (!patientData) {
                patientData = initialPatientForm
            }
            return {
                ...state,
                patientFormModalOpen: true,
                initialPatientForm: patientData
            }
            
        case types.uiClosePatientFormModal:
            return {
                ...state,
                patientFormModalOpen: false,
                initialPatientForm: initialPatientForm
            } 
        case types.uiPatientFormErrors:
            return {
                ...state,
                patientFormErrors: action.payload
            }

        case types.uiPatientFormErrorsReset:
            return {
                ...state,
                patientFormErrors: {}
            }

        case types.uiPatientFormValidated: 
            return {
                ...state,
                patientFormValidate: action.payload
            }    
        default:
            return state;
    }
}