import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import Swal from 'sweetalert2';

import { useDispatch, useSelector } from 'react-redux';
import { shiftStartDelete } from '../../actions/shifts';


const useStyles = makeStyles((theme) => ({
    deleteShiftFab: {
      [theme.breakpoints.between("xs", "sm", "md")]: {
        right: '3rem',
      },
      position: 'absolute',
      right: '5rem',
      bottom: '5rem',
      zIndex: '3'
    }
  }));


export const DeleteShiftFab = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { activeShift } = useSelector(state => state.calendar);

    const handleDelete = ()  => {
      Swal.fire({
        title: '<p>¡Cuídado!</p>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        text:  'Está seguro que desea eliminar el turno?'
      })
      .then( (result) => {
          if (result.isConfirmed) {
              dispatch( shiftStartDelete(activeShift) );
          }
      });
    }

    return (
        <Fab className={classes.deleteShiftFab} 
             color="secondary"
             onClick={handleDelete} 
             aria-label="edit">
            <DeleteIcon />
        </Fab>
    )
}
