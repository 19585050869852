import { combineReducers } from 'redux';
import { calendarReducer } from './calendarReducer';
import { uiReducer } from './uiReducer';
import { authReducer } from './authReducer';
import { osReducer } from './osReducer';
import { patientReducer } from './patientReducer';
import { staticsReducer } from './staticsReducer';
import { userReducer } from './userReducer';


export const rootReducer = combineReducers({
    ui: uiReducer,
    calendar: calendarReducer,
    auth : authReducer,
    os: osReducer,
    patient: patientReducer,
    statics: staticsReducer,
    user: userReducer,
});


